export function getOrderStatus({ order = {}, items = [] }) {
  const status = {
    phase: "訂單成立",
    title: "訂單成立",
    subtitle: "",
    step: 0,
    icon: "",
  };
  if (order) {
    if (order.is_logistics_delivered) {
      // step 6 出貨完成

      status.title = "訂單完成";
      status.subtitle = "此筆訂單已經完成，請於3日內確認商品。";
      status.icon = "/images/order_completed.svg";
      status.step = 6;
    } else if (order.is_item_manufacture) {
      // step 4 印製完成

      status.title = "出貨階段";
      status.subtitle = "訂單正在配送中，請注意物流來電。";
      status.icon = "/images/item_tallying.svg";
      status.step = 4;
    } else if (order.is_item_accepted) {
      // step 3 審稿完成

      status.title = "印製階段";
      status.subtitle = "稿件審稿合格將安排印製。";
      status.icon = "/images/item_accepted.svg";
      status.step = 3;

      if (
        Array.isArray(items) &&
        items.filter(
          x =>
            ["pending", "preparing", "in_production"].indexOf(
              x.production_status
            ) > -1
        ).length
      ) {
        status.subtitle = "您的商品正在印製，請耐心等候。";
        status.icon = "/images/item_in_production.svg";
      }
    } else if (order.payment_status === "success") {
      // step 2 付款完成

      // status.title = "審稿階段";
      status.title = "訂單付款完成";
      status.subtitle = "付款成功，我們將盡快為您處理商品。";
      status.icon = "/images/order_completed.svg";
      status.step = 2;

      if (
        Array.isArray(items) &&
        items.filter(x => ["waiting"].indexOf(x.status) > -1).length
      ) {
        status.subtitle = "正等待審稿人員審核稿件。";
        status.icon = "/images/item_unassigned.svg";
      }

      if (
        Array.isArray(items) &&
        items.filter(x => ["pending"].indexOf(x.status) > -1).length
      ) {
        status.subtitle = "稿件審核中";
        status.icon = "/images/item_pending.svg";
      }

      if (order.is_item_rejected) {
        status.subtitle = "您的稿件審查不合格，請重新上傳稿件。";
        status.icon = "/images/item_rejected.svg";
        status.step = 2;
      }
    } else {
      //step 1 付款階段
      status.title = "付款階段";
      status.icon = "/images/item_pending.svg";
      status.subtitle = {
        processing: "未付款",
        waiting: "未付款",
        failure: "付款失敗",
      }[order.payment_status];
      status.step = 1;

      if (order.payment_status === "failure") {
        status.subtitle = "請嘗試重新付款或聯絡客服人員。";
        status.icon = "/images/order_failed.svg";
      } else if (["waiting", "processing"].indexOf(order.payment_status) > -1) {
        status.icon = "/images/payment_waiting.svg";
        status.subtitle = "請前往訂單繳費。";
        if (order.payment_type === "monthly") {
          status.subtitle = "工作人員確認後，將安排審核稿件。";
        }
      } else {
        // greenjump
        status.subtitle = "請前往訂單繳費。";
      }
    }

    //特例
    if (["extra", "credit"].indexOf(order.order_type) > -1) {
      status.title = "訂單完成";
      status.subtitle = "此筆訂單已經完成。";
      status.icon = "/images/order_completed.svg";
      status.step = 6;

      if (order.payment_status !== "success") {
        status.title = "付款階段";
        status.subtitle = "請前往訂單繳費，付款將安排審核稿件。";
        status.icon = "/images/payment_waiting.svg";
        status.step = 1;
      }
    }
  }
  return status;
}
