import React from "react";
import * as Ant from "antd";
import styled from "styled-components";
import { connect } from "react-redux";
import ActionCreator from "../ActionCreator";
import * as Icon from "../Components/Icon";

function Customer({ customer, onClick, selected }) {
  return (
    <div
      onClick={onClick}
      style={{
        display: "flex",
        alignItems: "center",
        padding: 8,
        margin: 8,
        borderBottom: "1px solid grey",
      }}
    >
      <div style={{ width: 40 }}>
        {selected && <Icon.Check size={24} color="black" />}
      </div>
      <div>
        <div>姓名: {customer.name || "---"}</div>
        <div>電話: {customer.phone || "---"}</div>
        <div>信箱: {customer.email || "---"}</div>
      </div>
    </div>
  );
}

class ChangeBuyerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      searching: false,
      customers: [],
      buyer: null,
    };
  }

  render() {
    const { loading, error, searching, buyer, customers } = this.state;
    const { open, order, onClose } = this.props;

    return (
      <Ant.Modal
        visible={open}
        title="變更訂單顧客"
        onCancel={() => {
          this._restoreToDefaultState();
          onClose();
        }}
        footer={null}
      >
        {error && <div style={{ color: "red" }}>{error}</div>}

        <h2>顧客資訊</h2>

        <div style={{ margin: "10px 0px" }}>
          <Ant.Input.Search
            placeholder="請輸入姓名,電話,或信箱"
            onSearch={this._searchCustomer}
            enterButton="搜尋顧客"
          />
        </div>

        {searching && (
          <Ant.Spin style={{ display: "block", margin: "0 auto" }} />
        )}

        {customers.map(customer => (
          <Customer
            customer={customer}
            selected={buyer && buyer.id === customer.id}
            onClick={() => this.setState({ buyer: customer })}
          />
        ))}

        <BottomBar>
          <Ant.Button
            type="primary"
            loading={loading}
            onClick={this._updateBuyer}
          >
            更新訂單
          </Ant.Button>
        </BottomBar>
      </Ant.Modal>
    );
  }

  _restoreToDefaultState = () => {
    this.setState({
      buyer: null,
      customers: [],
    });
  };

  _searchCustomer = async keyword => {
    const { appActions } = this.props;

    if (!keyword) {
      return;
    }

    this.setState({ searching: true, buyer: null, customers: [] });

    try {
      this.setState({ customers: await appActions.searchProfile({ keyword }) });
    } catch (ex) {
      // bypass
    }

    this.setState({ searching: false });
  };

  _updateBuyer = async () => {
    const { appActions, onClose, order } = this.props;
    const { buyer } = this.state;

    if (!buyer) {
      return;
    }

    this.setState({ loading: true });

    try {
      await appActions.adminUpdateOrderBuyer({
        id: order.id,
        buyer: buyer.id,
      });
      alert("已成功變更訂單客戶");
      this._restoreToDefaultState();
      onClose();
    } catch (ex) {
      alert("欄位格式有誤！");
    }

    this.setState({ loading: false });
  };
}

const BottomBar = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
`;

export default connect((state, ownProps) => ({}), ActionCreator, null, {
  forwardRef: true,
})(ChangeBuyerModal);
