import React from "react";
import styled from "styled-components";
import ActionCreator from "../ActionCreator";
import Selectors from "../Selectors";
import { connect } from "react-redux";
import { withPage } from "../PageContainer";
import * as Cart from "../Contexts/CartContext";
import CartStepTabs from "../Components/CartStepTabs";
import OrderPreview from "../Components/OrderPreview";
import * as Ant from "antd";
import Constants from "../constants";
import ChangeBuyerModal from "../Modals/ChangeBuyerModal";
import * as Theme from "../Theme";
import { getOrderStatus } from "../Utils/OrderUtil";
import moment from "moment";

const queryString = require("query-string");

const PAYMENT_STATUS = {
  waiting: "等待付款",
  processing: "等待付款",
  code_generated: "等待付款",
  success: "付款成功",
  failure: "付款失敗",
};

class OrderDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      checkoutLoading: false,
      order: null,
      orderItems: null,
    };
  }

  async componentDidMount() {
    const { location } = this.props;
    const qs = queryString.parse(location.search);

    this.setState({ loading: true });
    try {
      if (!qs.id) {
        return;
      }

      let order = await Cart.Actions.fetchOrder(qs.id);
      this.setState({ order });
    } catch (ex) {}

    this.setState({ loading: false });
  }

  render() {
    const { profile } = this.props;
    const { order, orderItems, loading } = this.state;
    return (
      <Wrapper>
        <div className="center-content">
          {loading ? (
            <Ant.Spin style={{ display: "block", margin: "20px auto" }} />
          ) : order ? (
            this._renderOrderDetail()
          ) : (
            <div>無此訂單</div>
          )}
        </div>
      </Wrapper>
    );
  }

  _renderOrderDetail = () => {
    const { step, order, orderItems, loading, checkoutLoading } = this.state;
    let cartData = JSON.parse(order.cart);
    let orderStatus = getOrderStatus({ order });
    console.log("orderItems--->", orderItems);
    if (loading) {
      return <Ant.Spin style={{ display: "block", margin: "20px auto" }} />;
    } else {
      return (
        <CartStepTabs step={4}>
          <Ant.Row gutter={16}>
            <Ant.Col xs={24} sm={24} md={8} lg={8} style={{ marginBottom: 10 }}>
              <OrderID>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ fontSize: 17, fontWeight: "bold" }}>
                    訂單編號：
                  </div>
                  <div style={{ marginLeft: 5, color: Theme.colors.main }}>
                    {order.id}
                  </div>
                </div>
                <div style={{ fontSize: 12, color: "#717171" }}>
                  {`訂單成立時間： ${moment(order.created).format(
                    "YYYY-MM-DD HH:mm"
                  )}`}
                </div>
              </OrderID>
            </Ant.Col>

            <Ant.Col
              xs={24}
              sm={24}
              md={16}
              lg={16}
              style={{ marginBottom: 10 }}
            >
              <ReviewStatus>
                <img src={orderStatus.icon} height="80" width="80" />
                <div className="col">
                  <div className="title">{orderStatus.title}</div>
                  <div className="subtitle"> {orderStatus.subtitle}</div>
                </div>
              </ReviewStatus>
            </Ant.Col>
          </Ant.Row>
          <OrderPreview cartData={cartData} order={order} viewOnly />
        </CartStepTabs>
      );
    }
  };

  // _getItemsByOrderId = async () => {
  //   let { order } = this.state;
  //   try {
  //     let orderItems = await Cart.Actions.getItemsByOrderId(order.id); // for attachment upload
  //     this.setState({ orderItems });
  //   } catch (ex) {
  //     console.warn(ex);
  //   }
  // };
}

const Wrapper = styled.div`
  padding: 20px;
  background-color: white;

  .center-content {
    max-width: 960px;
    margin: 20px auto;
  }
`;

const OrderID = styled.div`
  border-top: 3px solid ${Theme.colors.main};
  background-color: #f7f7f7;
  padding: 16px;
  min-height: 85px;
`;

const ReviewStatus = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid #ccc;
  min-height: 85px;

  & > .col {
    display: flex;
    flex-direction: column;
    padding: 16px 0px;

    & > .title {
      font-size: 17px;
      font-weight: bold;
      color: ${Theme.colors.main};
    }

    & > .subtitle {
      font-size: 12px;
      color: #717171;
    }
  }
`;

const Footer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 600px) {
    margin-bottom: 100px;
  }
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state),
    }),
    ActionCreator
  )(OrderDetailPage)
);
